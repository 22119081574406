import { ChangeEvent, useEffect, useState, useRef, useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Logo from '../../components/Logo';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-image-crop/dist/ReactCrop.css';
import { UPLOAD_PROFILE_PICTURE_MUTATION } from '../../graphql/uploadProfilePicture';
import { useMutation } from '@apollo/react-hooks';
import { useNavigate } from 'react-router-dom';
import ProfilePicture from './ProfilePicture';
import CardDetails from './CardDetails';
import CardCodes from './CardCodes';
import UploadPictureDialog from '../../components/UploadPictureDialog';
import TabPanel from '../../components/TabPanel';
import resizeFile from '../../utils/resizeFile';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import useNetwork from '../../hooks/useNetwork';
import { useServiceWorker } from '../../hooks/useServiceWorker';
import packageJson from '../../../package.json';
import useIosInstallPrompt from '../../hooks/useIosInstallPrompt';
import IosShareIcon from '@mui/icons-material/IosShare';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const QRCode = require('qrcode.react');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Barcode = require('react-barcode');

const IMAGE_QUALITY = 0.2;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ProfilePage() {
  const {
    currentUser: customerProfile,
    error,
    loading,
    shouldRedirect,
    logout,
  } = useCurrentUser();

  const [tab, setTab] = useState(0);
  const [uploadModalTab, setUploadModalTab] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (customerProfile?.profilePicture?.url) {
      setProfilePicture(customerProfile?.profilePicture?.url);
    } else {
      setProfilePicture('');
    }
  }, [customerProfile]);

  useEffect(() => {
    if (error) {
      navigate('/');
      //console.log('error', error);
    }
    if (shouldRedirect) {
      navigate('/');
    }
  }, [error, navigate, shouldRedirect]);

  // Profile picture handling
  const [profilePicture, setProfilePicture] = useState<string>(
    customerProfile?.profilePicture?.url || '',
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isFileSelectorOpen, setIsFileSelectorOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  // Cropping
  const [upImg, setUpImg] = useState<string | null>(null);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const [installModal, setInstallModal] = useState<boolean>(false);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const isOnline = useNetwork();
  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      console.log(
        'onFileSelect event.target.files[0]: ',
        event.target.files[0],
      );
      setSelectedFile(event.target.files[0]);
      console.log('onFileSelect selectedFile: ', selectedFile);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result as string));
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const [isError, setIsError] = useState<any>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [missingUploadBlob, setMissingUploadBlob] = useState(false);

  const uploadFileToProfilePicture = async () => {
    setIsUploading(true);
    if (previewCanvasRef?.current) {
      console.log(
        'uploadFileToProfilePicture previewCanvasRef?.current: ',
        previewCanvasRef?.current,
      );
      previewCanvasRef.current.toBlob(
        async (blob) => {
          console.log('ProfilePage: start resizing...');
          setIsResizing(true);
          console.log('uploadFileToProfilePicture blob: ', blob);
          if (blob) {
            console.log('ProfilePage: we have blob');
            setMissingUploadBlob(false);
            try {
              console.log('ProfilePage: send blob to resizer...');
              const resizedImageBlob = (await resizeFile(blob)) as Blob;
              console.log('ProfilePage: resizing finished!');
              setIsResizing(false);
              console.log('ProfilePage: start uploading...');
              uploadProfilePicture({ variables: { data: resizedImageBlob } });
            } catch (error) {
              if (error instanceof Error) {
                setIsError(error?.message);
              } else {
                setIsError(error);
              }
              console.log(error);
              setIsResizing(false);
            } finally {
              console.log('uploadFileToProfilePicture finally');
              setIsResizing(false);
            }
          } else {
            console.log('uploadFileToProfilePicture missing blob!');
            setMissingUploadBlob(true);
            setIsUploading(false);
            setIsResizing(false);
          }
        },
        'image/webp',
        IMAGE_QUALITY,
      );
    }
  };

  const handleMutationStateChange = () => {
    // Redirect user to first tab after successful image upload
    // This is important for the second (and after) opening the upload modal
    setUploadModalTab(0);
    setIsUploading(false);
  };

  const [
    uploadProfilePicture,
    { data: uploadedProfilePictureResponse, loading: isUploadMutationLoading },
  ] = useMutation(UPLOAD_PROFILE_PICTURE_MUTATION, {
    onCompleted: (data) => {
      console.log('Image upload mutation completed!', data);
      setIsError(null);
      handleMutationStateChange();
    },
    onError: (error) => {
      console.log('Error during Image upload mutation:', error);
      setIsError(error);
      handleMutationStateChange();
    },
  });

  useEffect(() => {
    const tempUser = JSON.parse(String(localStorage.getItem('user')));
    if (uploadedProfilePictureResponse) {
      setProfilePicture(
        uploadedProfilePictureResponse?.uploadProfilePicture?.url,
      );
      setIsUploading(false);
      setIsFileSelectorOpen(false);
      setSelectedFile(null);
      if (tempUser != null) {
        tempUser.profilePicture.url =
          uploadedProfilePictureResponse?.uploadProfilePicture?.url;
        if (tempUser.profilePicture.timeOfApproval !== null) {
          tempUser.profilePicture.timeOfApproval = null;
        }
        localStorage.setItem('user', JSON.stringify(tempUser));
      }
    }
  }, [uploadedProfilePictureResponse]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleOpen = () => {
    setLogoutModal(true);
  };
  const handleClose = () => {
    setLogoutModal(false);
  };

  const [iosInstallPrompt] = useIosInstallPrompt();

  const handleInstallOpen = () => {
    setInstallModal(true);
  };
  const handleInstallClose = () => {
    setInstallModal(false);
  };

  const dateValidUntil = customerProfile?.elbCards?.[0]?.expirationDate
    ? new Date(
        customerProfile?.elbCards?.[
          customerProfile?.elbCards?.length - 1
        ]?.expirationDate,
      )
    : null;

  const elbCardNumber = useMemo(() => {
    return customerProfile?.elbCards?.[customerProfile?.elbCards?.length - 1]
      ?.cardNumber;
  }, [customerProfile]);

  const onlineCardNumber = useMemo(() => {
    return customerProfile?.elbCards?.[customerProfile?.elbCards?.length - 1]
      ?.onlineCardNumber;
  }, [customerProfile]);

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  const handleUpdateNotificationOpen = () => {
    if (showReload && waitingWorker) {
      return true;
    } else return false;
  };

  const handleUpdateNotificationClose = () => {
    reloadPage();
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        height: '100vh',
        padding: 0,
        margin: 0,
      }}
      disableGutters
    >
      <CssBaseline />
      <Logo />
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="fullWidth"
        centered
      >
        <Tab
          disabled={loading}
          icon={<AccountCircleIcon />}
          label="Profile picture"
          {...a11yProps(0)}
        />
        <Tab
          disabled={loading}
          icon={<QrCode2Icon />}
          label="QR code"
          {...a11yProps(1)}
        />
      </Tabs>
      <Snackbar
        open={handleUpdateNotificationOpen()}
        // autoHideDuration={6000}
        onClose={handleUpdateNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Box>
          <Alert
            onClose={handleUpdateNotificationClose}
            severity="info"
            sx={{ width: '100%' }}
          >
            <>
              A new version of this page is available
              <Button
                variant="outlined"
                sx={{ marginTop: '8px' }}
                onClick={() => reloadPage()}
              >
                REFRESH
              </Button>
            </>
          </Alert>
        </Box>
      </Snackbar>

      <TabPanel value={tab} index={0}>
        {loading ? (
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            marginTop="100px"
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Grid container>
              <Grid item xs={12}>
                <ProfilePicture
                  setIsFileSelectorOpen={setIsFileSelectorOpen}
                  profilePicture={profilePicture}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: '16px' }}>
                <CardCodes
                  elbCardNumber={elbCardNumber}
                  onlineCardNumber={onlineCardNumber}
                />
                <Grid item xs={12}>
                  <CardDetails dateValidUntil={dateValidUntil} />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  paddingTop: '16px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div>
                  {iosInstallPrompt && (
                    <>
                      <Button
                        variant="outlined"
                        sx={{ marginBottom: '8px' }}
                        onClick={() => handleInstallOpen()}
                      >
                        Install
                      </Button>
                      <Dialog
                        open={installModal}
                        onClose={handleInstallClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {'Install App'}
                        </DialogTitle>
                        <DialogContent>
                          Tap &nbsp;
                          <IosShareIcon />
                          &nbsp; then &quot;
                          <AddBoxOutlinedIcon />
                          Add to Home Screen&quot;
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => handleInstallClose()}>
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </>
                  )}
                  <br />
                  <Button variant="contained" onClick={() => handleOpen()}>
                    Logout
                  </Button>
                  <Dialog
                    open={logoutModal}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {'Are you sure you want to log out?'}
                    </DialogTitle>
                    <DialogActions>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Button onClick={() => handleClose()}>Cancel</Button>
                        <Button variant="contained" onClick={() => logout()}>
                          Logout
                        </Button>
                      </Stack>
                    </DialogActions>
                  </Dialog>
                </div>
              </Grid>
            </Grid>
          </Stack>
        )}
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          marginTop="8px"
        >
          <Box width="100%" display="flex" justifyContent="center">
            {(onlineCardNumber || elbCardNumber) && (
              <Stack
                spacing={6}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {!isOnline && (
                  <Box>
                    <Alert severity={'error'} sx={{ width: '100%' }}>
                      You are offline
                    </Alert>
                  </Box>
                )}
                <QRCode
                  value={onlineCardNumber ?? elbCardNumber}
                  size={250}
                  bgColor={'#ffffff'}
                  fgColor={'#000'}
                />
                <Box display="flex" justifyContent="center" width="90%">
                  <Barcode
                    width={2}
                    displayValue={false}
                    lineColor="#000"
                    value={onlineCardNumber ?? elbCardNumber}
                  />
                </Box>
              </Stack>
            )}
          </Box>
          {packageJson?.version && <p>App version: {packageJson.version}</p>}
        </Stack>
      </TabPanel>
      <UploadPictureDialog
        isOpen={isFileSelectorOpen}
        isUploading={isUploading}
        onClose={() => {
          setIsFileSelectorOpen(false);
          // Redirect user to first tab after closing the upload modal
          // This is important for the second (and after) opening the upload modal
          setSelectedFile(null);
          // setTimeout is needed for better UX
          // Without setTimeout the user could see the tab changes during closing the modal
          setTimeout(() => {
            setUploadModalTab(0);
          }, 500);
        }}
        onFileSelect={onFileSelect}
        previewCanvasRef={previewCanvasRef}
        selectedFile={selectedFile}
        upImg={upImg}
        uploadFileToProfilePicture={uploadFileToProfilePicture}
        tab={uploadModalTab}
        setTab={setUploadModalTab}
        isResizing={isResizing}
        isUploadMutationLoading={isUploadMutationLoading}
        missingUploadBlob={missingUploadBlob}
        setMissingUploadBlob={setMissingUploadBlob}
      />
      <Snackbar
        open={isError}
        autoHideDuration={6000}
        onClose={() => setIsError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Box>
          <Alert
            onClose={() => setIsError(null)}
            severity="error"
            sx={{ width: '100%' }}
          >
            Error! {JSON.stringify(isError)}
          </Alert>
        </Box>
      </Snackbar>
    </Container>
  );
}
