import { Box, List, ListItem, ListItemText, Alert } from '@mui/material';
import { isFuture, format, differenceInCalendarDays } from 'date-fns';
import { useContext } from 'react';
import CurrentUserContext from '../../contexts/CurrentUserContext';

interface CardDetailsProps {
  dateValidUntil: Date | null;
}

const CardDetails = (props: CardDetailsProps) => {
  const { dateValidUntil } = props;

  const { currentUser } = useContext(CurrentUserContext);

  const commonFontSizeStyle = {
    style: { fontSize: '1.25rem' },
  };

  // const commonLargerFontSizeObject = {
  //   fontSize: '1.5rem',
  // };

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        fontSize: '1.5rem',
      }}
    >
      {currentUser && (
        <div>
          <List dense sx={{ fontSize: '1.5rem' }}>
            <ListItem sx={{ wordBreak: 'break-all' }}>
              <ListItemText
                primary="Email"
                secondary={currentUser?.email}
                primaryTypographyProps={commonFontSizeStyle}
                secondaryTypographyProps={commonFontSizeStyle}
              />
            </ListItem>
            <ListItem>
              {dateValidUntil ? (
                isFuture(dateValidUntil) ? (
                  <ListItemText
                    primary={`Valid until ${format(
                      dateValidUntil,
                      'yyyy-MM-dd',
                    )}`}
                    secondary={`${differenceInCalendarDays(
                      dateValidUntil,
                      new Date(),
                    )} days`}
                    primaryTypographyProps={commonFontSizeStyle}
                    secondaryTypographyProps={commonFontSizeStyle}
                  />
                ) : (
                  <ListItemText
                    primary={`Invalid card!`}
                    secondary={`Expired on ${format(
                      dateValidUntil,
                      'yyyy-MM-dd',
                    )}`}
                    primaryTypographyProps={commonFontSizeStyle}
                    secondaryTypographyProps={commonFontSizeStyle}
                  />
                )
              ) : currentUser?.elbCards?.length === 0 ? (
                <Box sx={{ width: '100%' }}>
                  <Alert severity="warning">
                    You do NOT have an ELB card yet.
                  </Alert>
                </Box>
              ) : (
                <ListItemText
                  primary={`Not valid yet!`}
                  primaryTypographyProps={commonFontSizeStyle}
                  secondaryTypographyProps={commonFontSizeStyle}
                />
              )}
            </ListItem>
          </List>
        </div>
      )}
    </Box>
  );
};

export default CardDetails;
