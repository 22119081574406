import { isPlainObject } from 'lodash';

export function transformValue(value) {
  if (value === '' || value === null || value === undefined) {
    return undefined;
  }
  return value;
}

/**
 * Facilitates simpler server validation.
 * Recursively transform all possible object fields.
 */
export function transformVariables(variables) {
  return Object.entries(variables).reduce((transformed, [key, value]) => {
    // eslint-disable-next-line no-param-reassign
    transformed[key] = isPlainObject(value)
      ? transformVariables(value)
      : transformValue(value);
    return transformed;
  }, {});
}

export function getOperationName(document) {
  return document.definitions[0].name.value;
}
