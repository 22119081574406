import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useCallback } from 'react';

export const GENERATE_OTP_MUTATION = gql`
  mutation generateOTP($email: String!) {
    generateOTP(email: $email)
  }
`;
export default function useGenerateOtpMutation(mutation, email, options = {}) {
  const [mutate, result] = useMutation(mutation, {
    variables: { email: email },
    ...options,
  });
  const simpleMutate = useCallback(
    async (callbackId) =>
      callbackId ? mutate({ variables: { email: email } }) : mutate(),
    [mutate, email],
  );
  return [simpleMutate, result];
}
