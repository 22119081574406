/** @see https://www.apollographql.com/docs/react/api/react-apollo/#optionsfetchpolicy */
const FETCH_POLICY = {
  CacheFirst: 'cache-first',
  CacheAndNetwork: 'cache-and-network',
  NetworkOnly: 'network-only',
  CacheOnly: 'cache-only',
  NoCache: 'no-cache',
};

export default FETCH_POLICY;
