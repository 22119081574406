import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useCallback } from 'react';

export const LOGIN_CUSTOMER_MUTATION = gql`
  mutation loginCustomer($email: String!, $password: String!) {
    loginCustomer(data: { email: $email, password: $password }) {
      email
      firstName
      lastName
      vid
      role {
        name
      }
      profilePicture {
        url
        approved
        timeOfApproval
      }
      token
      elbCards {
        id
        status
        cardNumber
        onlineCardNumber
        expirationDate
      }
    }
  }
`;
export default function useGenerateOtpMutation(mutation, email, options = {}) {
  const [mutate, result] = useMutation(mutation, {
    variables: { email: email },
    ...options,
  });
  const simpleMutate = useCallback(
    async (callbackId) =>
      callbackId ? mutate({ variables: { email: email } }) : mutate(),
    [mutate, email],
  );
  return [simpleMutate, result];
}
