import gql from 'graphql-tag';

export const UPLOAD_PROFILE_PICTURE_MUTATION = gql`
  mutation uploadProfilePicture($data: String!) {
    uploadProfilePicture(data: $data) {
      url
      approved
      timeOfApproval
    }
  }
`;
