import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import AppRoutes from './routes/Routes';
import primaryTheme from './theme/main';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ApolloProvider } from '@apollo/react-hooks';
import setupApolloClient from './apollo/setupApolloClient';
import { useState } from 'react';
import {
  CurrentUser,
  CurrentUserProvider,
} from './contexts/CurrentUserContext';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
import { GlobalLogOutProvider } from './contexts/GlobalLogOutContext';
// REST API client
const queryClient = new QueryClient();
// GraphQL client
const client: any = setupApolloClient();

const LOCAL_USER = JSON.parse(String(localStorage.getItem('user')));

function App() {
  const [currentUser, setCurrentUser] = useState<CurrentUser | undefined>(
    LOCAL_USER ? LOCAL_USER : undefined,
  );
  const [globalLogOut, setGlobalLogOut] = useState<string | undefined>(
    undefined,
  );

  return (
    <ThemeProvider theme={primaryTheme}>
      <CurrentUserProvider value={{ currentUser, setCurrentUser }}>
        <GlobalLogOutProvider value={{ globalLogOut, setGlobalLogOut }}>
          <ApolloProvider client={client}>
            <QueryClientProvider client={queryClient}>
              <AddToHomeScreen
                styles={{ button: { backgroundColor: '#3F51B5' } }}
                skipFirstVisit={false}
                translate={{
                  headline: 'This webapp can be installed to your device',
                }}
              />
              <AppRoutes />
            </QueryClientProvider>
          </ApolloProvider>
        </GlobalLogOutProvider>
      </CurrentUserProvider>
    </ThemeProvider>
  );
}

export default App;
