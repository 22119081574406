import { Box, Stack, Alert, Avatar, Chip, Button } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import HelpIcon from '@mui/icons-material/Help';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useNetwork from '../../hooks/useNetwork';
import CurrentUserContext from '../../contexts/CurrentUserContext';

interface ProfilePictureProps {
  setIsFileSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  profilePicture: string;
}

const ProfilePicture = (props: ProfilePictureProps) => {
  const { setIsFileSelectorOpen, profilePicture } = props;

  const { currentUser } = useContext(CurrentUserContext);
  const [isEvaluated, setIsEvaluated] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const isOnline = useNetwork();

  useEffect(() => {
    if (currentUser) {
      setIsEvaluated(currentUser?.profilePicture?.timeOfApproval !== null);
      setIsValidated(currentUser?.profilePicture?.approved === true);
      setIsValid(currentUser?.profilePicture?.approved === true);
    }
  }, [currentUser]);

  const hasElbCard = useMemo(() => {
    if (currentUser?.elbCards?.length) {
      return currentUser.elbCards.length > 0;
    } else {
      return false;
    }
  }, [currentUser]);

  return (
    <Box display="flex" justifyContent="center">
      <Stack spacing={2} justifyContent="center" alignItems="center">
        {currentUser?.elbCards?.length === 0 && (
          <Box sx={{ width: '100%' }}>
            <Alert severity="warning">You do NOT have an ELB card yet.</Alert>
          </Box>
        )}
        {!isOnline && (
          <Box>
            <Alert severity={'error'} sx={{ width: '100%' }}>
              You are offline
            </Alert>
          </Box>
        )}
        <Avatar
          alt="My profile portrait"
          src={profilePicture}
          sx={{ width: '300px', height: '300px' }}
        >
          <PersonIcon sx={{ fontSize: 125 }} />
        </Avatar>
        <Stack
          spacing={2}
          sx={{
            width: '100%',
            margin: '8px',
          }}
        >
          {!isEvaluated && (
            <Chip
              color="warning"
              icon={<HelpIcon />}
              label="Missing validation!"
            />
          )}
          {isValidated && isValid ? (
            <Chip
              color="success"
              icon={<CheckCircleIcon />}
              label="Validated"
            />
          ) : (
            isEvaluated && (
              <Chip
                color="warning"
                icon={<HelpIcon />}
                label="Missing validation!"
              />
            )
          )}
          <Chip
            color="warning"
            icon={<ErrorIcon />}
            label="Only valid with ID card!"
          />
        </Stack>
        {hasElbCard && isValidated !== true && (
          <Button
            variant="contained"
            onClick={() => setIsFileSelectorOpen(true)}
          >
            Update picture
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default ProfilePicture;
