import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Paper from '@mui/material/Paper';
import EmailForm from '../components/EmailForm';
import PinForm from '../components/PinForm';
import Logo from '../components/Logo';
import useCurrentUser from '../hooks/useCurrentUser';

const LoginPage: React.FC = () => {
  const { currentUser: customerProfile, loading } = useCurrentUser();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 2;
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && customerProfile) {
      navigate('profile');
    }
  }, [customerProfile, loading, navigate]);

  const handleNext = useCallback(
    () =>
      setActiveStep((prevActiveStep) =>
        prevActiveStep <= 1 ? prevActiveStep + 1 : prevActiveStep,
      ),
    [],
  );

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep <= 0 ? prevActiveStep : prevActiveStep - 1,
    );
  };

  const loginToRestrictedPage = () => {
    navigate('profile');
  };

  const getStepName = () => {
    switch (activeStep) {
      case 0:
        return 'Type your email address';
      case 1:
        return 'Validate your PIN code';
      default:
        return 'Type your email address';
    }
  };

  const [email, setEmail] = useState('');

  const getStep = () => {
    switch (activeStep) {
      case 0:
        return <EmailForm goToNextStep={handleNext} setEmail={setEmail} />;
      case 1:
        return (
          <PinForm
            loginToRestrictedPage={loginToRestrictedPage}
            email={email}
          />
        );
      default:
        return <EmailForm goToNextStep={handleNext} setEmail={setEmail} />;
    }
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        height: '100vh',
        padding: 0,
        margin: 0,
      }}
      disableGutters
    >
      <CssBaseline />
      <Logo />
      <Paper
        square
        elevation={0}
        sx={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: 4,
          mt: '8px',
          bgcolor: 'background.default',
          textAlign: 'center',
        }}
      >
        <Typography>{getStepName()}</Typography>
        <Box sx={{ maxWidth: 400, width: '100%', p: 2 }}>{getStep()}</Box>
      </Paper>

      <AppBar
        position="fixed"
        color="transparent"
        sx={{ top: 'auto', bottom: 0, left: 0, maxWidth: 'sm', zIndex: 999 }}
      >
        <MobileStepper
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            activeStep === maxSteps - 1 ? (
              <Button disabled={activeStep === maxSteps - 1} />
            ) : (
              <Button
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            )
          }
          backButton={
            <Button onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
          sx={{
            color: 'black',
            bgcolor: 'background.default',
          }}
        />
      </AppBar>
    </Container>
  );
};

export default LoginPage;
