/* eslint-disable no-console */
import { isEmpty, isString } from 'lodash';

/** Extract all errors into object of arrays */
export function extractApiErrors({ graphQLErrors }) {
  const globalErrors = [];
  const fieldErrors = {};
  graphQLErrors.forEach(({ message }) => {
    if (isString(message)) {
      globalErrors.push(message);
    } else if (message.value) {
      globalErrors.push(message.value);
    } else {
      const { globals, fields } = message;
      globalErrors.push(...globals);
      fields.forEach(({ path, message: fmessage }) => {
        const fieldError = fieldErrors[path];
        if (fieldError) {
          fieldErrors[path].append(fmessage);
        } else {
          fieldErrors[path] = [fmessage];
        }
      });
    }
  });
  return { globals: globalErrors, fields: fieldErrors };
}

/** Reduce extracted errors to 1 global message, and 1 message for each field */
export function reduceApiErrors({ globals, fields }) {
  const reducedGlobalError = globals[0];
  const reducedFieldErrors = Object.entries(fields).reduce(
    (rfe, [path, messages]) => ({
      ...rfe,
      [path]: messages[0],
    }),
    {},
  );
  return {
    global: reducedGlobalError || null,
    fields: !isEmpty(reducedFieldErrors) ? reducedFieldErrors : null,
  };
}

export function transformApiError(error) {
  return reduceApiErrors(extractApiErrors(error));
}

export function printGlobalValidationError(message) {
  console.warn(`[Global Validation Error]: Message: ${message}`);
}

export function printFieldValidationError({ message, path }) {
  console.warn(`[Field Validation Error]: Message: ${message}, Path: ${path}`);
}

export function printGraphQLErrors(graphQLErrors) {
  graphQLErrors.forEach(({ message, path, stack }) => {
    const { id, value, globals, fields } = message;
    if (value) {
      console.error(`ID: ${id} Message: ${value}, Path: ${path}`);
      if (stack) console.error(stack);
    } else {
      console.warn(`[Validation Error]: ID: ${id} Path: ${path}`);
      globals.forEach(printGlobalValidationError);
      fields.forEach(printFieldValidationError);
      if (stack) console.warn(stack);
    }
  });
}

export function printNetworkError(networkError) {
  console.error(`[Network Error]: ${networkError}`);
}

export function printErrors({ networkError, graphQLErrors }) {
  if (networkError) printNetworkError(networkError);
  if (graphQLErrors) printGraphQLErrors(graphQLErrors);
}
