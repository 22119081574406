import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import CurrentUserContext from '../contexts/CurrentUserContext';
import { GET_CURRENT_CUSTOMER } from '../graphql/getCurrentCustomer';
import { useNavigate } from 'react-router-dom';
import GlobalLogOutContext from '../contexts/GlobalLogOutContext';

const useCurrentUser = () => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { setGlobalLogOut } = useContext(GlobalLogOutContext);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const [getCustomerProfile, { loading, error, data: customerProfileData }] =
    useLazyQuery(GET_CURRENT_CUSTOMER, {
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    //if (!currentUser) {
    const token = localStorage.getItem('ELB_ERASMUS_CARD_TOKEN');
    if (token) {
      getCustomerProfile({
        variables: {
          token: token,
        },
      });
    } else {
      setShouldRedirect(true);
    }
    //}
  }, []);

  useEffect(() => {
    if (customerProfileData) {
      const { getCustomerProfile } = customerProfileData;
      setCurrentUser?.(getCustomerProfile);
      const token = localStorage.getItem('ELB_ERASMUS_CARD_TOKEN');
      if (customerProfileData.token && !token) {
        localStorage.setItem(
          'ELB_ERASMUS_CARD_TOKEN',
          customerProfileData.token,
        );
      }
    }
  }, [customerProfileData, setCurrentUser]);

  useEffect(() => {
    if (error) {
      //console.log('error', Object.entries(error));
      //console.log('error', error.graphQLErrors);
      if (error?.graphQLErrors) {
        for (const graphQLError of error.graphQLErrors) {
          const graphQLErrorMessage: any = graphQLError?.message;
          if (
            graphQLErrorMessage.value ===
            'Authentication token is not provided!'
          ) {
            setGlobalLogOut?.('forced');
            localStorage.removeItem('user');
            localStorage.removeItem('ELB_ERASMUS_CARD_TOKEN');
          }
        }
      }
    }
  }, [error]);

  //TODO: warn user about log out
  const navigate = useNavigate();
  const logout = () => {
    // Explicit remove items
    localStorage.removeItem('user');
    localStorage.removeItem('ELB_ERASMUS_CARD_TOKEN');
    setCurrentUser?.(undefined);
    navigate('/');
  };

  return {
    loading,
    error,
    currentUser,
    shouldRedirect,
    logout,
  };
};

export default useCurrentUser;
