import Resizer from 'react-image-file-resizer';

const resizeFile = (file: Blob) =>
  new Promise((resolve, reject) => {
    console.log('resizeFile: New Resizer Promise with blob:', file);
    try {
      console.log('resizeFile: call Resizer.imageFileResizer');
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        'PNG',
        100,
        0,
        (uri) => {
          console.log('resizeFile:uri callback:', uri);
          console.log('resizeFile: resolve promise');
          resolve(uri);
        },
        'base64',
      );
    } catch (error) {
      console.log('resizeFile: catch (error)', error);
      console.error(error);
      reject(error);
    }
  });

export default resizeFile;
