import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Stack,
  Typography,
  Box,
  Button,
  DialogActions,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import FileUploadIcon from '@mui/icons-material/FileUpload';

interface UploadPictureDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedFile: File | null;
  upImg: string | null;
  previewCanvasRef: RefObject<HTMLCanvasElement>;
  isUploading: boolean;
  uploadFileToProfilePicture: () => Promise<void>;
  onFileSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  tab: number;
  setTab(newTabNumber: number): void;
  isResizing: boolean;
  isUploadMutationLoading: boolean;
  missingUploadBlob: boolean;
  setMissingUploadBlob: (value: boolean) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const UploadPictureDialog = (props: UploadPictureDialogProps) => {
  const {
    isOpen,
    onClose,
    selectedFile,
    upImg,
    previewCanvasRef,
    isUploading,
    uploadFileToProfilePicture,
    onFileSelect,
    tab,
    setTab,
    isResizing,
    isUploadMutationLoading,
    missingUploadBlob,
    setMissingUploadBlob,
  } = props;

  const defaultCrop: Partial<Crop> = {
    unit: '%',
    width: 30,
    aspect: 1,
  };
  const [crop, setCrop] = useState<Crop>(defaultCrop as Crop);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const [conditionCheck, setConditionCheck] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [hasOngoingImageManipulation, setHasOngoingImageManipulation] =
    useState(false);
  const [timerValue, setTimerValue] = useState<number | null>(null);

  const onLoad = useCallback((img: HTMLImageElement) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef?.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    console.log('UploadPictureDialog: ', { image, canvas, crop });

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    console.log('UploadPictureDialog: ', { scaleX, scaleY, ctx, pixelRatio });

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    if (ctx) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      console.log({
        cropWidth: crop.width,
        cropHeight: crop.height,
        cropXScaleX: crop.x * scaleX,
        cropYScaleY: crop.y * scaleY,
        cropWidthScaleX: crop.width * scaleX,
        cropHeightScaleX: crop.height * scaleY,
      });

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
    }
  }, [completedCrop, previewCanvasRef]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  // Size of the selected file
  const fileSizeInKiloBytes = useMemo(() => {
    if (selectedFile) {
      const sizeInKb = selectedFile.size / 1024;
      let sizeInMb = null;
      if (sizeInKb > 1024) {
        sizeInMb = sizeInKb / 1024;
      }
      const relevantSize = sizeInMb || sizeInKb;
      const sizeInKbFixed = relevantSize.toFixed(2);
      return sizeInKbFixed;
    } else {
      return 0;
    }
  }, [selectedFile]);

  // Name of the selected file
  const selectedFileName = useMemo(() => {
    if (selectedFile) {
      return selectedFile.name;
    } else {
      return '';
    }
  }, [selectedFile]);

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={isOpen} onClose={onClose}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Instructions" {...a11yProps(0)} />
            <Tab label="Upload" {...a11yProps(1)} />
            <Tab label="Preview" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tab} index={0}>
          <DialogTitle>Instructions/Conditions</DialogTitle>
          <ul>
            <li>
              A color photo formatted similar to a ID card, Student Card,
              driver's license or passport photo
            </li>
            <li>The background must be a solid white or a light color</li>
            <li>
              Your face and shoulders must be in the frame and be facing forward
            </li>
            <li>
              NO sunglasses, hats, funny faces, props/emojis, or anyone else in
              the photo
            </li>
            <li>NO photo-shopped or altered photos</li>
          </ul>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              component="span"
              sx={{ marginTop: '8px' }}
              onClick={() => setTab(1)}
            >
              Next
            </Button>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <DialogTitle>Upload image from your device</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="fileInput"
                multiple={false}
                type="file"
                onChange={(e) => {
                  onFileSelect(e);
                  if (e.target.files) {
                    setTab(2);
                  }
                }}
              />
              <Box
                display="flex"
                width="100%"
                justifyContent="center"
                paddingTop="8px"
              >
                <label htmlFor="fileInput">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{ marginTop: '8px' }}
                  >
                    Select image
                  </Button>
                </label>
              </Box>
            </DialogContentText>
          </DialogContent>
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={2}>
          <DialogTitle>Preview</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {selectedFile && (
                <>
                  <Stack spacing={2}>
                    <Typography component={'span'}>Selected image</Typography>
                    <Typography component={'span'}>
                      {selectedFileName} ({fileSizeInKiloBytes} kB)
                    </Typography>
                    <ReactCrop
                      src={upImg ?? ''}
                      onImageLoaded={onLoad}
                      crop={crop}
                      onChange={(c: Crop) => {
                        setTimerValue(new Date().getTime());
                        setHasOngoingImageManipulation(true);
                        console.log('onChange: ', { c, crop });
                        setCrop(c);
                      }}
                      onComplete={(c: any) => {
                        setHasOngoingImageManipulation(false);
                        setMissingUploadBlob(false);
                        if (timerValue) {
                          console.log(
                            `Manipulation duration`,
                            new Date().getTime() - timerValue,
                          );
                        }
                        console.log('onComplete: ', completedCrop);
                        setCompletedCrop(c);
                      }}
                      minWidth={100}
                      minHeight={100}
                      onDragStart={() => {
                        console.log('onDragStart');
                      }}
                      onDragEnd={() => {
                        console.log('onDragEnd');
                      }}
                    />
                    <Typography component={'span'}>
                      Preview {hasOngoingImageManipulation && '(processing...)'}{' '}
                      {isResizing && '(resizing...)'}{' '}
                      {isUploadMutationLoading && '(uploading...)'}
                    </Typography>
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box
                        display="flex"
                        width="100%"
                        justifyContent="center"
                        flexDirection="row"
                      >
                        <canvas
                          ref={previewCanvasRef}
                          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                          style={{
                            width: Math.round(completedCrop?.width ?? 100),
                            height: Math.round(completedCrop?.height ?? 100),
                          }}
                        />
                      </Box>
                      {missingUploadBlob && (
                        <Box
                          sx={{
                            display: 'flex',
                            width: '100%',
                            textAlign: 'center',
                          }}
                          justifyContent="center"
                        >
                          <Typography
                            component={'span'}
                            sx={{
                              color: 'red',
                              fontWeight: 600,
                              textAlign: 'center',
                            }}
                          >
                            Missing cropped image to upload! Try re-cropping the
                            image!
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </Stack>
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        onChange={(e) => {
                          setConditionCheck(e.target.checked);
                        }}
                      />
                    }
                    label="Does your picture meet the conditions?"
                  />
                </>
              )}
            </DialogContentText>
          </DialogContent>
        </CustomTabPanel>
      </Box>
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Button onClick={onClose}>Close</Button>
          <LoadingButton
            onClick={() => uploadFileToProfilePicture()}
            disabled={!selectedFile || !conditionCheck}
            endIcon={<FileUploadIcon />}
            loading={isUploading}
            loadingPosition="end"
            variant="contained"
          >
            Upload
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default UploadPictureDialog;
