import Box from '@mui/material/Box';
import LogoImage from '../logo.png';

const Logo: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        py: '4px',
        backgroundColor: 'rgb(63, 81, 181)',
      }}
    >
      <img
        src={LogoImage}
        alt="Erasmus Life Budapest"
        height="75px"
        width="75px"
      />
    </Box>
  );
};

export default Logo;
