/* eslint-disable @typescript-eslint/no-var-requires */
import {
  Grid,
  Box,
  Stack,
  Typography,
  ListItem,
  List,
  ListItemText,
} from '@mui/material';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import BlackLogoImage from '../../logo-black.png';
import '../../App.css';
import CurrentUserContext from '../../contexts/CurrentUserContext';

const QRCode = require('qrcode.react');
const Barcode = require('react-barcode');

interface CardCodesProps {
  elbCardNumber: string | undefined;
  onlineCardNumber?: string;
}

const CardCodes = (props: CardCodesProps) => {
  const { elbCardNumber, onlineCardNumber } = props;

  const [currentTimestamp, setCurrentTimestamp] = useState(new Date());
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentTimestamp(new Date());
    }, 1000);
    return () => clearTimeout(timer);
  });
  const { currentUser } = useContext(CurrentUserContext);

  const commonLargerFontSizeObject = {
    fontSize: '1.5rem',
  };
  const commonFontSizeStyle = {
    style: { fontSize: '1.25rem' },
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        fontSize: '1.5rem',
      }}
    >
      {currentUser && (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Grid item xs={10}>
            <List dense sx={{ fontSize: '1.5rem' }}>
              <ListItem>
                <ListItemText
                  primary="Name"
                  secondary={`${currentUser?.firstName} ${currentUser?.lastName}`}
                  primaryTypographyProps={commonFontSizeStyle}
                  secondaryTypographyProps={commonFontSizeStyle}
                />
              </ListItem>
            </List>
          </Grid>
          <Typography
            component="p"
            fontWeight={900}
            align="center"
            sx={{
              ...commonLargerFontSizeObject,
              marginTop: '8px',
            }}
          >
            {format(currentTimestamp, 'yyyy-MM-dd HH:mm:ss')}
          </Typography>
          <Typography
            component="p"
            fontWeight={900}
            align="center"
            sx={{
              ...commonLargerFontSizeObject,
              marginTop: '8px',
              marginBottom: '8px',
            }}
          >
            The card is not valid on screenshots.
          </Typography>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              {(onlineCardNumber || elbCardNumber) && (
                <Stack spacing={2} justifyContent="center" alignItems="center">
                  <QRCode
                    value={onlineCardNumber ?? elbCardNumber}
                    size={150}
                    bgColor={'#ffffff'}
                    fgColor={'#000'}
                  />
                </Stack>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <Box display="flex">
                <Stack spacing={2} justifyContent="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        py: '4px',
                      }}
                    >
                      <img
                        src={BlackLogoImage}
                        alt="Erasmus Life Budapest"
                        height="75px"
                        width="75px"
                        className="black-logo"
                      />
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          {(onlineCardNumber || elbCardNumber) && (
            <Grid item xs={12} display="flex" justifyContent="center">
              <Box display="flex" justifyContent="center" width="90%">
                <Barcode
                  width={2}
                  displayValue={false}
                  lineColor="#000"
                  value={onlineCardNumber ?? elbCardNumber}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              width="100%"
              marginTop="16px"
            >
              {onlineCardNumber && (
                <Stack>
                  <Typography
                    component="p"
                    fontWeight={100}
                    align="center"
                    mt="20px"
                    sx={{ fontSize: '1.2rem' }}
                  >
                    Online ELB Card number:
                  </Typography>
                  <Typography
                    component="p"
                    fontWeight={900}
                    align="center"
                    sx={{
                      ...commonLargerFontSizeObject,
                    }}
                  >
                    {onlineCardNumber}
                  </Typography>
                </Stack>
              )}
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default CardCodes;
