import React, { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Alert,
  AlertColor,
  Button,
  LinearProgress,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { LOGIN_CUSTOMER_MUTATION } from '../graphql/loginCustomer';
import { useMutation } from '@apollo/react-hooks';
import { GENERATE_OTP_MUTATION } from '../graphql/generateOTP';
import { useServiceWorker } from '../hooks/useServiceWorker';

interface PinFormProps {
  loginToRestrictedPage: () => void;
  email: string;
}

const PinForm: React.FC<PinFormProps> = ({ loginToRestrictedPage, email }) => {
  const [openApiResponseSnackbar, setOpenApiResponseSnackbar] =
    React.useState(false);
  const [snackVariant, setSnackVariant] = useState<AlertColor>('success');
  const [snackText, setSnackText] = useState(
    'Open your email inbox and get your PIN code!',
  );

  const [login] = useMutation(GENERATE_OTP_MUTATION);
  //const { setCurrentUser } = useContext(CurrentUserContext);

  const [sendPinCode, { loading, error, data }] = useMutation(
    LOGIN_CUSTOMER_MUTATION,
    {
      onCompleted: (c) => {
        if (c?.loginCustomer) {
          //setCurrentUser?.(c?.loginCustomer);
          //localStorage.setItem('user', JSON.stringify(c?.loginCustomer)); //ez test ha elromlana valami torold ki
          if (c?.loginCustomer?.token) {
            localStorage.setItem(
              'ELB_ERASMUS_CARD_TOKEN',
              c?.loginCustomer?.token,
            );
          }
          setSnackVariant('success');
          setSnackText(
            'Successful PIN validation! We send you to the next step...',
          );
          setOpenApiResponseSnackbar(true);
          loginToRestrictedPage();
        }
      },
      onError: () => {
        setSnackVariant('error');
        setSnackText('Invalid PIN code, check your email again!');
        setOpenApiResponseSnackbar(true);
      },
    },
  );

  const isSuccess = useMemo(() => {
    return data?.token || false;
  }, [data]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenApiResponseSnackbar(false);
  };

  const validationSchema = yup.object({
    pinCode: yup
      .string()
      .matches(/^[0-9-]+$/, 'Must be only digits')
      .min(7, 'Must be exactly 6 digits')
      .max(7, 'Must be exactly 6 digits')
      .required('You must add your PIN code from your email!'),
  });

  const formik = useFormik({
    initialValues: {
      pinCode: '',
    },
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  useEffect(() => {
    if (isSuccess) {
      setSnackVariant('success');
      setSnackText(
        'Successful PIN validation! We send you to the next step...',
      );
      setOpenApiResponseSnackbar(true);
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      setSnackVariant('error');
      setSnackText('Something went wrong. Please try again later!');
      setOpenApiResponseSnackbar(true);
    }
  }, [error]);

  const handleValidatePinButtonClick = () => {
    sendPinCode({
      variables: {
        email: email,
        password: formik.values.pinCode.replace('-', ''),
      },
    });
  };

  const handleReRequestPinButtonClick = () => {
    login({ variables: { email: email } });
  };

  const customOnChangeForPinCode = (event: any) => {
    let newValue = event.target.value;
    if (newValue.length <= 7) {
      if (newValue.length > 3 && newValue.includes('-') === false) {
        newValue = newValue.slice(0, 3) + '-' + newValue.slice(3);
      }
      formik.setFieldValue('pinCode', newValue, true);
    }
  };

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  const handleUpdateNotificationOpen = () => {
    if (showReload && waitingWorker) {
      return true;
    } else return false;
  };

  const handleUpdateNotificationClose = () => {
    reloadPage();
  };

  return (
    <Box>
      <Snackbar
        open={openApiResponseSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box>
          <Alert
            onClose={handleClose}
            severity={snackVariant}
            sx={{ width: '100%' }}
          >
            {snackText}
            {snackVariant === 'success' && (
              <LinearProgress color="success" sx={{ marginTop: '8px' }} />
            )}
          </Alert>
        </Box>
      </Snackbar>
      <Snackbar
        open={handleUpdateNotificationOpen()}
        // autoHideDuration={6000}
        onClose={handleUpdateNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Box>
          <Alert
            onClose={handleUpdateNotificationClose}
            severity="info"
            sx={{ width: '100%' }}
          >
            <>
              A new version of this page is available
              <Button
                variant="outlined"
                sx={{ marginTop: '8px' }}
                onClick={() => reloadPage()}
              >
                REFRESH
              </Button>
            </>
          </Alert>
        </Box>
      </Snackbar>
      <TextField
        variant="outlined"
        fullWidth
        id="pinCode"
        name="pinCode"
        label="PIN code"
        autoComplete="pinCode"
        value={formik.values.pinCode}
        onChange={customOnChangeForPinCode}
        error={formik.touched.pinCode && Boolean(formik.errors.pinCode)}
        helperText={formik.touched.pinCode && formik.errors.pinCode}
        inputProps={{
          autoCapitalize: 'none',
        }}
      />
      <Typography variant="body1" sx={{ marginTop: '16px' }}>
        Your PIN code is 6 digits. It is valid for 3 minutes. After expiration,
        you have to go to step 1.
      </Typography>
      <Typography variant="body1" sx={{ marginTop: '8px' }}>
        Type your PIN code from your email, touch the <b>Validate PIN code</b>{' '}
        button, then go to the next step!
      </Typography>
      <Stack spacing={2}>
        <Button
          variant="contained"
          disabled={!(formik.isValid && formik.dirty) || loading}
          onClick={() => handleValidatePinButtonClick()}
          sx={{ marginTop: '16px' }}
        >
          Validate PIN code
        </Button>
        <Button
          variant="outlined"
          sx={{ marginTop: '8px' }}
          disabled={!email || loading}
          onClick={() => handleReRequestPinButtonClick()}
        >
          Re-request PIN code
        </Button>
      </Stack>
    </Box>
  );
};

export default PinForm;
