/* eslint-disable */
import currentUser from './currentUser';

export default {
  Query: {
    ...currentUser.Query,
  },
  Mutation: {
    ...currentUser.Mutation,
  },
};
